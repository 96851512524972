import React from "react";
import {Col, Row, Table} from 'reactstrap';	
import ExportStats from './exportstats';

class VerkoopOmzet extends React.Component {
	render() {
		return (
			<Row style={{paddingTop: 35}}>
				<Col lg={12}>
					{this.props.stats !== 'loading' ?
					<div className="graph-display">
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, fontWeight: 900, fontSize: 20, color: '#212529',}}>Totalen</h2>		
							<div style={{display: 'flex'}}>
								<div className='filebtn' onClick={() => this.exportRef.exportCSV('xlsx',this.props.stats,this.props.exportInfo)}>XLSX</div>
								<div className='filebtn' onClick={() => this.exportRef.exportCSV('pdf',this.props.stats,this.props.exportInfo)}>PDF</div>
							</div>
						</div>	
						<div className="statsTable">
							<Table className="v-middle" responsive style={{marginTop: 15}}>
								<thead>
									<tr>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Item</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Aantal</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Transacties</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>BTW</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Omzet incl.</td>
									</tr>
								</thead>
								<tbody>
									<tr style={{background: this.getCellBg()}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>Muntjes via online transacties</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.online.aantal}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.online.transacties}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>-</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {this.state.online.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									</tr>
									{Object.keys(this.state.oplaadStats).map((oplaadpunt, index) => (										
										<tr style={{background: this.getCellBg()}}>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>Muntjes via {oplaadpunt}</h6></td>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.oplaadStats[oplaadpunt].tokens}</h6></td>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.oplaadStats[oplaadpunt].transacties}</h6></td>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>-</h6></td>
											<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {this.state.oplaadStats[oplaadpunt].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										</tr>	
									))}
									<tr style={{background: this.getCellBg()}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>Muntjes geretourneerd</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.retourStats.aantal}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.retourStats.transacties}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>-</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#e53935', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ -{this.state.retourStats.retourkosten.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									</tr>									
									<tr style={{background: this.getCellBg()}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>Kluisjes</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.kluisStats ? this.state.kluisStats.aantal : 0}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.kluisStats ? this.state.kluisStats.transacties : 0}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {this.state.kluisStats ? this.state.kluisStats.btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {this.state.kluisStats ? this.state.kluisStats.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
									</tr>	
									<tr style={{background: this.getCellBg()}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>Transactiekosten<i class="fa fa-info-circle serviceInfoIcon" style={{position: 'relative', color: 'rgba(0,0,0,0.5)', marginLeft: 5, marginTop: 2}}><div className="serviceInfoContainer"><div className="serviceInfoText">Wanneer er is gekozen om transactiekosten door te berekenen aan bezoekers worden deze inkomsten bijgeschreven op het boeksaldo van de organisatie. Partypay B.V. factureert de transactiekosten (€ {this.state.serviceKosten.partypay.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}) door aan de organisatie.</div></div></i></h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>-</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.serviceKosten.transacties}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {(this.state.serviceKosten.partypayPaid / (1.21) * 0.21).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {this.state.serviceKosten.partypayPaid.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									</tr>
									<tr style={{background: this.getCellBg()}}>
										<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 16, fontWeight: 900}}>Totaal</h6></td>
										<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 16, fontWeight: 900}}>-</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.serviceKosten.transacties}</h6></td>
										<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 16, fontWeight: 900}}>€ {this.state.totaalStats.btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{paddingTop: 8, fontSize: 16, fontWeight: 900, textAlign: 'center'}}>€ {this.state.totaalStats.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
					:
					<div className="campaign ct-charts graph-display" style={{height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div class="loading-spinner" style={{height: 50, width: 50}}></div>
					</div>
					}
				</Col>
				<ExportStats ref={ref => this.exportRef = ref} />
			</Row>	
		);
	}	
	
	state = {
		online: {omzet: 0, aantal: 0, transacties: 0},
		retourStats: {aantal: 0, transacties: 0, retourkosten: 0},
		retourStats: {aantal: 0, transacties: 0, retourkosten: 0},
		kluisStats: {transacties: 0, aantal: 0, omzet: 0, btw: 0},
		serviceKosten: {transacties: 0, partypay: 0, partypayPaid: 0},
		oplaadStats: {},
		totaalStats: {omzet: 0, btw: 0}
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.stats !== prevProps.stats && this.props.stats !== 'loading'){			
			var online = {omzet: 0, aantal: 0, transacties: 0};	
			var retourStats = {aantal: 0, transacties: 0, retourkosten: 0};	
			var kluisStats = {transacties: 0, aantal: 0, omzet: 0, btw: 0};
			var serviceKosten = {transacties: 0, partypay: 0, partypayPaid: 0};	
			var oplaadStats = {};		
			var totaalStats = {omzet: 0, btw: 0};
			Object.values(this.props.stats).forEach(statsDate => {
				if(statsDate.tokenverkoop){
					online.aantal = online.aantal + (statsDate.tokenverkoop.aantal ? statsDate.tokenverkoop.aantal : 0);
					online.transacties = online.transacties + (statsDate.tokenverkoop.transacties ? statsDate.tokenverkoop.transacties : 0);
					online.omzet = online.omzet + (statsDate.tokenverkoop.omzet && statsDate.tokenverkoop.omzet ? statsDate.tokenverkoop.omzet : 0);
					totaalStats.omzet = totaalStats.omzet + (statsDate.tokenverkoop.omzet && statsDate.tokenverkoop.omzet ? statsDate.tokenverkoop.omzet : 0);
				}
				if(statsDate.oplaadpunten){
					Object.keys(statsDate.oplaadpunten).forEach(oplaadpunt => {
						oplaadStats[oplaadpunt] = {
							tokens: (oplaadpunt in oplaadStats ? oplaadStats[oplaadpunt].tokens : 0) + statsDate.oplaadpunten[oplaadpunt].tokens,
							transacties: (oplaadpunt in oplaadStats ? oplaadStats[oplaadpunt].transacties : 0) + statsDate.oplaadpunten[oplaadpunt].transacties,
							omzet: (oplaadpunt in oplaadStats ? oplaadStats[oplaadpunt].omzet : 0) + statsDate.oplaadpunten[oplaadpunt].omzet
						};
						totaalStats.omzet = totaalStats.omzet + statsDate.oplaadpunten[oplaadpunt].omzet;
					});		
				}
				if(statsDate.tokenretour){
					retourStats.aantal = retourStats.aantal + statsDate.tokenretour.aantal;
					retourStats.transacties = retourStats.transacties + statsDate.tokenretour.transacties;
					retourStats.retourkosten = retourStats.retourkosten + statsDate.tokenretour.retourkosten;
					totaalStats.omzet = totaalStats.omzet - statsDate.tokenretour.retourkosten;
				}
				if(statsDate.kluisverkoop && statsDate.kluisverkoop.aantal){			
					kluisStats.aantal = kluisStats.aantal + statsDate.kluisverkoop.aantal;
					kluisStats.omzet = kluisStats.omzet + statsDate.kluisverkoop.omzet;
					kluisStats.transacties = kluisStats.transacties + statsDate.kluisverkoop.transacties;
					kluisStats.btw = kluisStats.btw + (statsDate.kluisverkoop.omzet / (1.21) * 0.21);
					totaalStats.omzet = totaalStats.omzet + statsDate.kluisverkoop.omzet;
					totaalStats.btw = totaalStats.btw + (statsDate.kluisverkoop.omzet / (1.21) * 0.21);
				}
				if(statsDate.servicekosten){
					serviceKosten.transacties = serviceKosten.transacties + statsDate.servicekosten.transacties;
					serviceKosten.partypay = serviceKosten.partypay + statsDate.servicekosten.partypay;
					serviceKosten.partypayPaid = serviceKosten.partypayPaid + statsDate.servicekosten.partypayPaid;
					totaalStats.omzet = totaalStats.omzet + statsDate.servicekosten.partypayPaid;
					totaalStats.btw = totaalStats.btw + (statsDate.servicekosten.partypayPaid / (1.21) * 0.21);
				}		
			});
			this.setState({'online': online, 'oplaadStats': oplaadStats, 'retourStats': retourStats, 'kluisStats': kluisStats, 'serviceKosten': serviceKosten, 'totaalStats': totaalStats});
		}
	}
	
	getCellBg = () => {
		this.cellBgCounter++;
		if(this.cellBgCounter%2 === 0){return null}
		else{return 'rgba(0,0,0,0.03)'}
	}	
	cellBgCounter = 0;
	
}

export default VerkoopOmzet;



