import React from 'react';
import arrayMove from 'array-move';
import Cropper from 'react-easy-crop';
import 'react-image-picker/dist/index.css';
import { RangeStepInput } from 'react-range-step-input';
import { db, storage } from '../components/firebase/firebase';
import {Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { observer, inject } from 'mobx-react';
import drinkPlaceholder from '../assets/images/drinkPlaceholder.png'
import Warning from '../assets/images/pp_warning.svg';

@inject('observableStore')
@observer

class Menu extends React.Component {
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Assortiment</h2>
					</Col>					
				</Row>
				<Row className="menuTopContainer">
					<Col lg={2} style={{paddingLeft: 0, paddingRight: 0}}>
						<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)'}} onChange={e => {this.selectMenu(e.target.value)}}>
							{this.props.observableStore.currEvent.menus ? this.props.observableStore.currEvent.menus.map((menu, index) => (	
								<option value={index} selected={this.state.currMenu === index}>{menu.label.charAt(0).toUpperCase() + menu.label.slice(1)}</option>
							)):null}
							<option disabled>-----------------------</option>
							<option value={'new'}>Nieuw menu</option>
						</Input>
					</Col>
					<Col className="menuRightActions">
						<div className="addBtnTop" style={{marginRight: 15, width: 150, marginBottom: 0}} onClick={() => this.toggleEditMenu()}>
							Wijzigen								
						</div>
						<div className="addBtnTop" style={{marginRight: 15, width: 150, marginBottom: 0}} onClick={() => this.clickImport()}>
							Importeren							
						</div>
						<div className="addBtnTop" style={{marginRight: 15, width: 150, marginBottom: 0}} onClick={() => this.exportMenu()}>
							Exporteren							
						</div>
						<div className="addBtnTop" style={{width: 200, marginBottom: 0, background: '#d12a5e', color: '#fff'}} onClick={() => this.newCat()}>
							Categorie toevoegen									
						</div>
						<Input innerRef={input => this.upload = input} type="file" name="upadlocsv" id="upadlocsv" style={{display: 'none'}} onChange={this.importMenu.bind(this)}/>
					</Col>
				</Row>				
				<Row className="menuTopCats">
					<Col lg={2} className="graph-display" style={{background: 'none', paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 275}}>
						{this.props.observableStore.currEvent.menus && this.props.observableStore.currEvent.menus[this.state.currMenu].menu.length > 0 ?
							<SortableGroupList items={this.props.observableStore.currEvent.menus[this.state.currMenu].menu} catStateGroep={this.state.groep} editGroup={this.editGroup} changeGroup={this.changeGroup} onSortEnd={this.onGroupSortEnd} useDragHandle={true} />
						:null}
					</Col>
					
					<Col className="graph-display graph-menu" style={{marginLeft: 20, padding: 40, paddingTop: 30}}>
						<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, fontWeight: 900, paddingRight: 20, fontSize: 20, color: '#212529',}}>{this.props.observableStore.currEvent.menus && this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep] ? this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].name : ''}</h2>
							<div style={{display: 'flex'}}>
								<div className="addBtnLst" style={{marginLeft: 15, width: 175}} onClick={() => this.newItem()}>
									Product toevoegen										
								</div>
							</div>
						</div>	
						{this.props.observableStore.currEvent.menus && this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep] && this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items.length > 0 ?
							<div style={{minHeight: 250}}>
								<SortableList items={this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items} editItem={this.editItem} onSortEnd={this.onSortEnd} useDragHandle={true}/>
							</div>
						:
							<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
								<img src={Warning} style={{width: 50, marginBottom: 15}} />
								<span>Er zijn nog geen producten toegevoegd</span>
							</div>
						}
					</Col>
				</Row>			
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
					<ModalHeader toggle={this.toggle} style={{marginBottom: 10}}>
						<a onClick={() => this.setState({productTab: 'product'})} style={{cursor: 'pointer', fontSize: 15, color: this.state.productTab === 'product' ? '#000' : 'rgba(0,0,0,0.5)'}}>Product</a> 
						<a onClick={() => this.setState({productTab: 'opties'})} style={{cursor: 'pointer', marginLeft: 25, fontSize: 15, color: this.state.productTab === 'opties' ? '#000' : 'rgba(0,0,0,0.5)'}}>Opties</a> 
						<div style={{marginLeft: this.state.productTab === 'product' ? -6 : 72, width: 70, height: 1, backgroundColor: '#d12a5e', position: 'absolute', marginTop: 16}}></div>
					</ModalHeader>
					<Row>
						<Col lg={12}>
							{this.state.productTab === 'product' ?
							<ModalBody>
								<Row>
									<Col sm={6}>
										<FormGroup>
											<Label htmlFor="example-email">Naam</Label>
											<Input type="text" style={{borderColor: this.state.reqFields.includes('newItemName') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Bier" value={this.state.newItemName} onChange={e => this.setState({newItemName: e.target.value.replace('/', '')})}/>
										</FormGroup>
									</Col>
									<Col sm={6}>
										<FormGroup>
											<Label htmlFor="example-email">Status</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemStatus: e.target.value})}>
												<option value="beschikbaar" selected={this.state.newItemStatus === "beschikbaar" ? true : false}>Op voorraad</option>
												<option value="uitverkocht" selected={this.state.newItemStatus === "uitverkocht" ? true : false}>Uitverkocht</option>
												<option value="verborgen" selected={this.state.newItemStatus === "verborgen" ? true : false}>Verborgen</option>
											</Input>
										</FormGroup>
									</Col>
								</Row>
								<Row style={{marginTop: 5}}>
									<Col sm={12}>
										<FormGroup>
											<Label htmlFor="example-email">Omschrijving</Label>
											<Input type="textarea" style={{borderColor: this.state.reqFields.includes('newItemInfo') ? 'red' : undefined}} id="inputArea" name="name" placeholder="Bijv: Beker 0.25ml" value={this.state.newItemInfo} onChange={e => this.setState({newItemInfo: e.target.value})}/>
										</FormGroup>	
									</Col>
								</Row>
								<Row style={{marginTop: 5}}>
									<Col sm={4} style={{paddingRight: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">Prijs</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemPrice: parseFloat(e.target.value)})}>
												<option value="0.5" selected={this.state.newItemPrice === 0.5 ? true : false}>0.5 Muntje</option>
												<option value="1" selected={this.state.newItemPrice === 1 ? true : false}>1 Muntje</option>
												<option value="1.5" selected={this.state.newItemPrice === 1.5 ? true : false}>1.5 Muntje</option>
												<option value="2" selected={this.state.newItemPrice === 2 ? true : false}>2 Muntjes</option>
												<option value="2.5" selected={this.state.newItemPrice === 2.5 ? true : false}>2.5 Muntjes</option>
												<option value="3" selected={this.state.newItemPrice === 3 ? true : false}>3 Muntjes</option>
												<option value="3.5" selected={this.state.newItemPrice === 3.5 ? true : false}>3.5 Muntjes</option>
												<option value="4" selected={this.state.newItemPrice === 4 ? true : false}>4 Muntjes</option>
												<option value="4.5" selected={this.state.newItemPrice === 4.5 ? true : false}>4.5 Muntjes</option>
												<option value="5" selected={this.state.newItemPrice === 5 ? true : false}>5 Muntjes</option>
												<option value="5.5" selected={this.state.newItemPrice === 5.5 ? true : false}>5.5 Muntjes</option>
												<option value="6" selected={this.state.newItemPrice === 6 ? true : false}>6 Muntjes</option>
												<option value="6.5" selected={this.state.newItemPrice === 6.5 ? true : false}>6.5 Muntjes</option>
												<option value="7" selected={this.state.newItemPrice === 7 ? true : false}>7 Muntjes</option>
												<option value="7.5" selected={this.state.newItemPrice === 7.5 ? true : false}>7.5 Muntjes</option>
												<option value="8" selected={this.state.newItemPrice === 8 ? true : false}>8 Muntjes</option>
												<option value="8.5" selected={this.state.newItemPrice === 8.5 ? true : false}>8.5 Muntjes</option>
												<option value="9" selected={this.state.newItemPrice === 9 ? true : false}>9 Muntjes</option>
												<option value="9.5" selected={this.state.newItemPrice === 9.5 ? true : false}>9.5 Muntjes</option>
												<option value="10" selected={this.state.newItemPrice === 10 ? true : false}>10 Muntjes</option>
												<option value="11" selected={this.state.newItemPrice === 11 ? true : false}>11 Muntjes</option>
												<option value="12" selected={this.state.newItemPrice === 12 ? true : false}>12 Muntjes</option>
												<option value="13" selected={this.state.newItemPrice === 13 ? true : false}>13 Muntjes</option>
												<option value="13.5" selected={this.state.newItemPrice === 13.5 ? true : false}>13.5 Muntjes</option>
												<option value="14" selected={this.state.newItemPrice === 14 ? true : false}>14 Muntjes</option>
												<option value="15" selected={this.state.newItemPrice === 15 ? true : false}>15 Muntjes</option>
												<option value="16" selected={this.state.newItemPrice === 16 ? true : false}>16 Muntjes</option>
												<option value="17" selected={this.state.newItemPrice === 17 ? true : false}>17 Muntjes</option>
												<option value="18" selected={this.state.newItemPrice === 18 ? true : false}>18 Muntjes</option>
												<option value="19" selected={this.state.newItemPrice === 19 ? true : false}>19 Muntjes</option>
												<option value="20" selected={this.state.newItemPrice === 20 ? true : false}>20 Muntjes</option>
												<option value="25" selected={this.state.newItemPrice === 25 ? true : false}>25 Muntjes</option>
												<option value="30" selected={this.state.newItemPrice === 30 ? true : false}>30 Muntjes</option>
												<option value="35" selected={this.state.newItemPrice === 35 ? true : false}>35 Muntjes</option>
												<option value="40" selected={this.state.newItemPrice === 40 ? true : false}>40 Muntjes</option>
											</Input>
										</FormGroup>
									</Col>
									<Col sm={3} style={{paddingRight: 10, paddingLeft: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">BTW</Label>
											<Input type="select" className='minimal' onChange={e => this.setState({newItemBTW: parseFloat(e.target.value)})}>
												<option value="0.09" selected={this.state.newItemBTW === 0.09 ? true : false}>9%</option>
												<option value="0.13" selected={this.state.newItemBTW === 0.13 ? true : false}>13%</option>
												<option value="0.21" selected={this.state.newItemBTW === 0.21 ? true : false}>21%</option>
												<option value="0" selected={this.state.newItemBTW === 0 ? true : false}>0%</option>
											</Input>
										</FormGroup>
									</Col>
									<Col sm={5} style={{paddingLeft: 10}}>
										<FormGroup>
											<Label htmlFor="example-email">Serveren</Label>
											<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => this.setState({newItemServe: e.target.value})}>
												<option value="direct" selected={this.state.newItemServe === 'direct'}>Direct serveren</option>
												<option value="bereiden" selected={this.state.newItemServe === 'bereiden'}>Bereiden</option>
											</Input>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									{this.props.observableStore.currEvent.hardcups.actief ?
									<Col sm={7}>
										<Row onClick={() => this.setState({newItemCup: !this.state.newItemCup})} style={{cursor: 'pointer', marginTop: 10, marginBottom: 7, marginLeft: 1}}>
											{this.state.newItemCup ?
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
												<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
											</div>
											:
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
											}
											<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Item komt in herbruikbare beker</p>
										</Row>
									</Col>
									:null}
									{this.props.observableStore.currEvent.idin ?
									<Col sm={5}>
										<Row onClick={() => this.setState({newItemEighteen: !this.state.newItemEighteen})} style={{cursor: 'pointer', marginTop: 10, marginBottom: 7, marginLeft: 1}}>
											{this.state.newItemEighteen ?
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
												<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
											</div>
											:
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
											}
											<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Alleen voor 18+</p>
										</Row>
									</Col>
									:null}
								</Row>
								<div style={{marginTop: 10}}>
									<Row>
										<Col sm={6}>
											<div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingTop: 10, paddingBottom: 10, backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: 7, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.reqFields.includes('newItemImg') ? 'red' : 'rgba(0,0,0,0)'}}>
												<img class="menu_img" src={this.state.newItemImg === '' ? drinkPlaceholder : this.state.newItemImg} height="94"/>
											</div>
										</Col>
										<Col sm={6}>
											<div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
												<div className="addBtnLst" style={{width: 100, fontSize: 12, paddingTop: 9, height: 34, marginBottom: 5}} onClick={() => this.imgUpload.click()}>Uploaden</div>
												<Input innerRef={input => this.imgUpload = input} style={{display: 'none'}} type="file" className="custom-file-input" id="customFile1" onChange={e => this.loadImg(e)} />
												<label className="col-form-label" style={{fontSize: 11}}>Upload een jpg of png afbeelding</label>
											</div>
										</Col>
									</Row>
									{this.state.imageSrc === '' ?
										<div style={{marginTop: 20, marginBottom: 10}}>
										{this.state.iconsLoaded ?
											<div>
												<div style={{maxHeight: 195, overflowY: 'scroll',}}>
													{this.state.iconList.map((image, i) => {
														if(this.state.searchInput.length < 2 || this.state.searchInput.length >= 2 && image.toLowerCase().includes(this.state.searchInput) || this.state.searchInput.length >= 2 && image.toLowerCase().includes(this.state.searchInput)){
														return <div style={{float: 'left', padding: 4, cursor: 'pointer'}} onClick={() => this.setState({newItemImg: image})}>
															<img src={image} height="43.9"/>
														</div>	
														}									
													})}
												</div>	
												<Input type="text" id="example-email" style={{marginTop: 10}} name="name" placeholder="Zoek op afbeeldingen" value={this.state.searchInput} onChange={e => this.setState({searchInput: e.target.value.toLowerCase()})}/>
											</div>
											:
											<div style={{display: 'flex', width: '100%', height: 195, justifyContent: 'center', alignItems: 'center'}}>
												<div class="loading-spinner" style={{height: 50, width: 50}}></div>
											</div>
											}
										</div>
									:
									<div style={{marginTop: 20}}>
										{this.state.uploadState ?
											<div style={{position: 'relative', width: '100%', height: 275, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
												<div class="loading-spinner" style={{width: 75, height: 75, borderWidth: 6}}></div>
											</div>
											:
											<div>
												<div style={{position: 'relative', width: '100%', height: 275, overflow: 'hidden'}}>
													<Cropper
														image={this.state.imageSrc}
														crop={this.state.crop}
														zoom={this.state.zoom}
														aspect={15 / 8}
														cropSize={{width: 330, height: 176}}
														onCropChange={this.onCropChange}
														onCropComplete={(croppedArea, croppedAreaPixels) => {this.setState({pixelCrop: croppedAreaPixels})}}
														restrictPosition={false}
														showGrid={false}
													/>
												</div>										
												<div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, overflow: 'hidden'}}>					
													<div style={{width: '100%'}}>
														<RangeStepInput style={{width: '100%'}} value={this.state.zoom} min={0} max={3} step={0.1} onChange={e => this.setState({zoom: e.target.value})}/>
													</div>
												</div>	
											</div>
										}
									</div>
									}
								</div>
							</ModalBody>
							:
							<ModalBody>
								<p style={{fontSize: 16, marginTop: 2, color: '#4a4a4a', fontWeight: 900}}>Extra opties</p>
								<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Je kunt extra opties toevoegen aan producten zoals bijvoorbeeld sauzen bij friet of frisdrank bij vodka.</p>
								<SortableOptieList helperClass='is-moving' items={this.state.newItemOpties} onSortEnd={this.onOptieSortEnd} editOptie={this.editOptie} delOptie={this.delOptie} useDragHandle={true} />
								<div onClick={() => this.newOptie()} style={{display: 'flex', justifyContent: 'center'}}><div className='addBtnLst' style={{marginTop: 25, marginBottom: 30, fontSize: 13, width: 160}}>Optie toevoegen</div></div>
								<Modal isOpen={this.state.optieModal} toggle={() => this.toggleOptieModal} className={this.props.className} centered style={{maxWidth: 400}} >
									<ModalHeader toggle={this.toggleOptieModal}>Optie {this.state.editOptieIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}</ModalHeader>
									<ModalBody>
										<FormGroup>
											<Label htmlFor="example-email">Optie tekst</Label>
											<Input type="text" id="example-email" name="name" placeholder="Bijv: met cola" value={this.state.newOptieText} onChange={e => this.setState({newOptieText: e.target.value.replace('/', '')})}/>
										</FormGroup>
									</ModalBody>
									<ModalFooter>
										{this.state.editOptieIndex !== 'new' ?
										<Button className="menuDelButton" onClick={this.delOptie}>
										verwijderen
										</Button>
										:null}
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.saveOptie()}>
											{this.state.editOptieIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
										</Button>
										<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleOptieModal}>
											Annuleren
										</Button>
									</ModalFooter>
								</Modal>
							</ModalBody>
							}
						</Col>
					</Row>
					<ModalFooter style={{justifyContent: 'space-between'}}>	
						<div>
							{this.state.editItemIndex !== 'new' && this.state.productTab === 'product' ?
							<Button className="menuDelButton" onClick={this.toggleDelModal}>
							  verwijderen
							</Button>
							:null}	
						</div>
						<div style={{display: 'flex', marginLeft: 0}}>
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.checkImg('item')}>
								{this.state.editItemIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
							</Button>
							<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggle}>
								Annuleren
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.groupModal} toggle={this.toggleGroup} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleGroup}>Menu categorie {this.state.editGroupInd >= 0 ? 'aanpassen' : 'toevoegen'}</ModalHeader>
					<ModalBody>
						<Row>
							<Col sm={8}>
								<FormGroup>
									<Label htmlFor="example-email">Naam</Label>
									<Input type="text" style={{borderColor: this.state.reqFields.includes('newGroupName') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Frisdrank" value={this.state.newGroupName} onChange={e => this.setState({newGroupName: e.target.value})}/>
								</FormGroup>
							</Col>
							<Col sm={4}>
								<FormGroup>
									<Label htmlFor="example-email">Nummer</Label><i class="fa fa-info-circle groepInfoIcon" style={{position: 'relative', color: 'rgba(0,0,0,0.5)', marginLeft: 5, marginTop: 2}}><div className="groepInfoText">Wanneer je categorieën een nummer geeft kunnen de items worden opgehaald i.c.m. items van andere categorieën met hetzelfde nummer.</div></i>
									<Input type="number" id="example-email" name="price" placeholder="Bijv: 10" value={this.state.newGroupLink} onChange={e => this.setState({newGroupLink: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<Label htmlFor="example-email">Type</Label><i class="fa fa-info-circle groepInfoIcon" style={{position: 'relative', color: 'rgba(0,0,0,0.5)', marginLeft: 5, marginTop: 2}}><div className="groepInfoText">Bij type 'open' worden de items van deze categorie direct zichtbaar. Bij type 'gesloten' moeten gebruikers op de catergorie klikken om de items te zien</div></i>
								<Input type="select" style={{marginBottom: 8, width: '100%', height: 35}} className='minimal' onChange={e => {this.setState({newGroupClosed: e.target.value})}}>
									<option value={'open'} selected={this.state.newGroupClosed === 'open'}>Open</option>
									<option value={'gesloten'} selected={this.state.newGroupClosed === 'gesloten'}>Gesloten</option>
								</Input>
							</Col>
							<Col sm={6}>
								<Label htmlFor="example-email">Status</Label>
								<Input type="select" style={{marginBottom: 8, width: '100%', height: 35}} className='minimal' onChange={e => {this.setState({newGroupState: e.target.value})}}>
									<option value={'zichtbaar'} selected={this.state.newGroupState === 'zichtbaar'}>Zichtbaar</option>
									<option value={'verborgen'} selected={this.state.newGroupState === 'verborgen'}>Verborgen</option>
								</Input>
							</Col>
						</Row>
						{this.state.newGroupClosed === 'gesloten' ?
						<Row style={{marginTop: 10}}>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Omschrijving</Label>
									<Input type="text" id="example-email" name="price" placeholder="Bijv: Pizzas, pasta en focaccia" value={this.state.newGroupDesc} onChange={e => this.setState({newGroupDesc: e.target.value})}/>
								</FormGroup>
							</Col>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Groepstitel</Label><i class="fa fa-info-circle groepInfoIcon" style={{position: 'relative', color: 'rgba(0,0,0,0.5)', marginLeft: 5, marginTop: 2}}><div className="groepInfoText">De groepstitel wordt boven de catergorie geplaatst. Dit zou je bijvoorbeerd voor de eerste categorie in een reeks kunnen doen om 'foodtrucks' aan te duiden.</div></i>
									<Input type="text" id="example-email" name="price" placeholder="Bijv: Foodtrucks" value={this.state.newGroupTitle} onChange={e => this.setState({newGroupTitle: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						:null}
						{this.state.newGroupClosed === 'gesloten' && this.state.imageSrc === '' ?
						<Row>
							<Col sm={6}>
								<Label htmlFor="example-email">Afbeelding</Label>
								<div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingTop: 10, paddingBottom: 10, backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: 7}}>
									<img class="menu_img" src={this.state.newGroupImg === '' ? drinkPlaceholder : this.state.newGroupImg} height="94"/>
								</div>
							</Col>
							<Col sm={6}>
								<div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
									<div className="addBtnLst" style={{width: 100, fontSize: 12, paddingTop: 9, height: 34, marginBottom: 5}} onClick={() => this.imgUpload.click()}>Uploaden</div>
									<Input innerRef={input => this.imgUpload = input} style={{display: 'none'}} type="file" className="custom-file-input" id="customFile1" onChange={e => this.loadImg(e)} />
									<label className="col-form-label" style={{fontSize: 11}}>Upload een jpg of png afbeelding</label>
								</div>
							</Col>
						</Row>
						:null}
						{this.state.newGroupClosed === 'gesloten' && this.state.imageSrc !== '' ?
						<Row>
							<Col sm={12}>
							{this.state.uploadState ?
							<div style={{position: 'relative', width: '100%', height: 275, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<div class="loading-spinner" style={{width: 75, height: 75, borderWidth: 6}}></div>
							</div>
							:
							<div>
								<div style={{position: 'relative', width: '100%', height: 400, overflow: 'hidden'}}>
									<Cropper
										image={this.state.imageSrc}
										crop={this.state.crop}
										zoom={this.state.zoom}
										aspect={2.09 / 1}
										cropSize={{width: 384, height: 184}}
										onCropChange={this.onCropChange}
										onCropComplete={(croppedArea, croppedAreaPixels) => {this.setState({pixelCrop: croppedAreaPixels})}}
										restrictPosition={false}
										showGrid={false}
									/>
								</div>										
								<div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, overflow: 'hidden'}}>					
									<div style={{width: '100%'}}>
										<RangeStepInput style={{width: '100%'}} value={this.state.zoom} min={0} max={3} step={0.1} onChange={e => this.setState({zoom: e.target.value})}/>
									</div>
								</div>	
							</div>
							}
							</Col>
						</Row>
						:null}
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div>
							{this.state.editGroupInd >= 0 ?
							<Button style={{backgroundColor: 'rgb(229, 57, 53)', border: 0}} onClick={this.delGroup}>
							  verwijderen
							</Button>
							:null}
						</div>
						<div style={{display: 'flex'}}>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={() => this.checkImg('group')}>
							  {this.state.editGroupInd >= 0 ? 'Aanpassen' : 'Toevoegen'}
							</Button>
							<Button className="cancel_btn" onClick={this.toggleGroup}>
							  Annuleren
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.newMenuModal} toggle={this.toggleNewMenu} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleNewMenu}>Nieuw menu</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Label</Label>
							<Input type="text" id="example-email" name="name" placeholder="Bijv: Festival menu" value={this.state.newMenuLabel} onChange={e => this.setState({newMenuLabel: e.target.value})}/>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addMenu}>
						  opslaan
						</Button>
						<Button className="cancel_btn" onClick={this.toggleNewMenu}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.editMenuModal} toggle={this.toggleEditMenu} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleEditMenu}>Menu wijzigen</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Label</Label>
							<Input type="text" id="example-email" name="name" placeholder="Bijv: Festival menu" value={this.state.newMenuLabel} onChange={e => this.setState({newMenuLabel: e.target.value})}/>
						</FormGroup>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div>
							<Button style={{backgroundColor: 'rgb(229, 57, 53)', border: 0}} onClick={this.delMenu}>
							  verwijderen
							</Button>
						</div>
						<div style={{display: 'flex'}}>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={this.editMenu}>
							  opslaan
							</Button>
							<Button className="cancel_btn" onClick={this.toggleEditMenu}>
							  Annuleren
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleInfoModal}>Informatie</ModalHeader>
					<ModalBody>
						<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
						  Sluit venster
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.delModal} toggle={() => this.toggleDelModal} className={this.props.className} centered style={{maxWidth: 400}} >
					<ModalHeader toggle={this.toggleDelModal}>Item verwijderen</ModalHeader>
					<ModalBody>
						<div style={{paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<img src={Warning} style={{width: 50, marginBottom: 15}} />
							<p style={{marginTop: 10, textAlign: 'center'}}><b>Let op!</b> Het is afgeraden om items tijdens een evenement te verwijderen. Het is geadviseerd om in dit geval de status op verborgen of uitverkocht te zetten.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button className="menuDelButton" style={{backgroundColor: '#d12a5e'}} onClick={this.delItem}>
						  verwijderen
						</Button>
						<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleDelModal}>
							Annuleren
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
	
	state = {
		modal: false, 
		groupModal: false,
		newMenuModal: false,
		editMenuModal: false,
		infoModal: false,
		delModal: false,

		optieModal: false,
		productTab: 'product',
		editOptieIndex: '',
		newItemOpties: [],
		newOptieText: '',

		currMenu: this.props.observableStore.currMenu,
		groep: this.props.observableStore.currMenuGroep,		
		newItemImg: '',
		newItemName: '',	
		newItemInfo: '',
		newItemServe: 'Direct serveren',
		newItemEighteen: false,
		newItemCup: false,
		newItemPrice: 1,
		newItemBTW: 0.09,
		newItemStatus: 'beschikbaar',
		newItemCat: 'drinken',
		newItemBarArr: [],
		editItemIndex: '',
		currBar: '',
		iconList: [],
		iconsLoaded: false,
		uploadState: false,
		
		newGroupName: '',
		newGroupLink: 'n.v.t.',
		newGroupState: 'zichtbaar',
		newGroupClosed: 'gesloten',
		newGroupDesc: '',
		newGroupTitle: '',
		newGroupImg: '',
		editGroupInd: -1,

		newMenuLabel: '',
		
		imageSrc: '',
		crop: { x: 0, y: 0 },
		zoom: 1,
		reqFields: [],

		searchInput: ''
		
	}
	
	toggle = () => this.setState({modal: !this.state.modal, productTab: 'product', imageSrc: '', reqFields: [], searchInput: ''});
	toggleGroup = () => {this.setState({groupModal: !this.state.groupModal, imageSrc: '', reqFields: []})}	
	toggleNewMenu = () => { this.setState({newMenuLabel: '', newMenuModal: !this.state.newMenuModal})}
	toggleEditMenu = () => { this.setState({newMenuLabel: this.props.observableStore.currEvent.menus[this.state.currMenu].label, editMenuModal: !this.state.editMenuModal})}
	toggleInfoModal = (error) => this.setState({infoModal: !this.state.infoModal, 'infoModalTxt': error});
	toggleDelModal = () => {this.setState({delModal: !this.state.delModal})}	
	toggleOptieModal = () => {this.setState({optieModal: !this.state.optieModal})}	

	
	newItem = () => {
		this.setState({editItemIndex: 'new', newItemName: '', newItemOpties: [], newItemImg: '', newItemPrice: 1, newItemBTW: 0.09, newItemStatus: 'beschikbaar'});
		if(this.state.iconList.length === 0){ this.getMenuIcons() }
		this.toggle();
	}

	editItem = (index, itemName, itemInfo, itemImg, itemPrice, itemBTW, itemServe, itemStatus, reqEighteen, opties, cup) => {
		this.setState({editItemIndex: index, newItemName: itemName, newItemOpties: opties ? opties : [], newItemInfo: itemInfo, newItemServe: itemServe, newItemEighteen: reqEighteen ? true : false, newItemCup: cup ? true : false, newItemImg: itemImg, newItemPrice: itemPrice, newItemBTW: itemBTW, newItemStatus: itemStatus});
		if(this.state.iconList.length === 0){ this.getMenuIcons() }
		this.toggle();
	}

	newOptie = () => {
		this.setState({editOptieIndex: 'new', newOptieText: ''});
		this.toggleOptieModal();
	}
	
	editOptie = (index) => {
		this.setState({editOptieIndex: index, newOptieText: this.state.newItemOpties[index]});
		this.toggleOptieModal();
	}

	delOptie = (ind) => {
		var newItemOpties = this.state.newItemOpties;
		newItemOpties.splice(ind, 1);
		this.setState({newItemOpties: newItemOpties});
	}

	saveOptie = () => {
		if(this.state.editOptieIndex === 'new'){
			var newItemOpties = this.state.newItemOpties;
			newItemOpties.push(this.state.newOptieText.toLowerCase());
			this.setState({newItemOpties: newItemOpties});
		}
		else{this.state.newItemOpties[this.state.editOptieIndex] = this.state.newOptieText.toLowerCase()}
		this.toggleOptieModal();
	}

	delItem = () => {
		var newItems = this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items;
		newItems.splice(this.state.editItemIndex, 1);
		this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items = newItems;
		this.props.observableStore.saveEventInfo('menu');		
		this.toggle();
		this.toggleDelModal();
	}
	
	getMenuIcons = () => {
		var iconList = [];
		var iconCount = 1;
		var refPromises = [];
		refPromises.push(storage.ref().child('menuIcons').listAll());
		refPromises.push(storage.ref().child('admins').child(this.props.observableStore.uid).child('menuIcons').listAll());		
		Promise.all(refPromises).then(res => {		  
			res.forEach(img => {
				img.items.forEach(imageRef => {	
					imageRef.getDownloadURL().then(url => {
						iconList.push(url);				
						if(iconCount === res[0].items.length + res[1].items.length){
							this.setState({iconList: iconList, iconsLoaded: true});
						}
						iconCount++;
					})					
				});
			});	  
		});	
	}
	
	checkImg = (type) => {
		if(type === 'item' && this.reqFields('item')){
			if(this.state.imageSrc !== ''){
				this.saveImage('item');
			}
			else{
				this.saveItem();
			}
		}
		if(type === 'group' && this.reqFields('group')){
			if(this.state.newGroupClosed === 'gesloten' && this.state.imageSrc !== ''){
				this.saveImage('group');
			}
			else{
				this.saveGroup();
			}
		}
	}

	saveImage(type){	 
		const canvas = document.createElement('canvas');
		var pixelCrop = this.state.pixelCrop;
		canvas.width = type === 'item' ? 330 : 768;
		canvas.height = type === 'item' ? 176 : 367;
		const ctx = canvas.getContext('2d');
		ctx.fillStyle = "#d3d8d9";
		ctx.fillRect(0, 0, pixelCrop.width, pixelCrop.height);
		var img = new Image();
		img.src = this.state.imageSrc;		
		img.onload = () => {
			ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvas.width, canvas.height);			
			canvas.toBlob(blob => {
				const filename = new Array(15).join().replace(/(.|$)/g, function(){return ((Math.random()*36)|0).toString(36)[Math.random()<.5?"toString":"toUpperCase"]()}) + '.jpeg';
				const file = new File([blob], filename, {type: blob.type});				
				this.setState({uploadState: true});
				if(type === 'item'){
					var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/menuIcons/' + file.name);
					ImageRef.getDownloadURL().then((url) => {this.setState({newItemImg: url, uploadState: false})}).catch(err => {							
						ImageRef.put(file).then(() =>{ImageRef.getDownloadURL().then((url) => {this.setState({newItemImg: url, uploadState: false, imageSrc: ''}); this.saveItem()})});
					})		
				}
				if(type === 'group'){
					var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/' + file.name);
					ImageRef.getDownloadURL().then((url) => {this.setState({newGroupImg: url, uploadState: false})}).catch(err => {							
						ImageRef.put(file).then(() =>{ImageRef.getDownloadURL().then((url) => {this.setState({newGroupImg: url, uploadState: false, imageSrc: ''}); this.saveGroup()})});
					})		
				}		
			},'image/jpeg');			
		}	
	}
	
	saveItem() {	
		var menuArr = this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items;			
		if(this.state.editItemIndex === 'new')
		{
			var newItem = {
				id: this.getUniqueID('item'), 
				name: this.state.newItemName, 
				info: this.state.newItemInfo,
				serve: this.state.newItemServe,
				img: this.state.newItemImg, 
				price: this.state.newItemPrice,
				btw: this.state.newItemBTW,
				status: this.state.newItemStatus,
				opties: this.state.newItemOpties
			}
			if(this.state.newItemEighteen){newItem['reqEighteen'] = true}
			if(this.state.newItemCup){newItem['cup'] = true}
			menuArr.push(newItem);
		}
		else{
			menuArr[this.state.editItemIndex].name = this.state.newItemName;
			menuArr[this.state.editItemIndex].info = this.state.newItemInfo;
			menuArr[this.state.editItemIndex].serve = this.state.newItemServe;
			menuArr[this.state.editItemIndex].img = this.state.newItemImg;
			menuArr[this.state.editItemIndex].price = this.state.newItemPrice;
			menuArr[this.state.editItemIndex].btw = this.state.newItemBTW;
			menuArr[this.state.editItemIndex].status = this.state.newItemStatus ? this.state.newItemStatus : 'beschikbaar';	
			menuArr[this.state.editItemIndex].opties = this.state.newItemOpties;
			if(this.state.newItemEighteen){menuArr[this.state.editItemIndex].reqEighteen = true}
			else if(menuArr[this.state.editItemIndex].reqEighteen){delete menuArr[this.state.editItemIndex].reqEighteen}
			if(this.state.newItemCup){menuArr[this.state.editItemIndex].cup = true}	
			else if(menuArr[this.state.editItemIndex].cup){delete menuArr[this.state.editItemIndex].cup}	
		}
		this.props.observableStore.saveEventInfo('menu');		
		this.toggle('close');
	}

	saveGroup = () => {		
		if(this.reqFields('group')){
			var menuArr = this.props.observableStore.currEvent.menus[this.state.currMenu].menu;
			if(this.state.editGroupInd >= 0){
				menuArr[this.state.editGroupInd].name = this.state.newGroupName;
				menuArr[this.state.editGroupInd].group = this.state.newGroupLink;
				menuArr[this.state.editGroupInd].status = this.state.newGroupState === 'zichtbaar' ? true : false;				
				menuArr[this.state.editGroupInd].closed = this.state.newGroupClosed === 'gesloten' ? true : false;
				menuArr[this.state.editGroupInd].desc = this.state.newGroupDesc ? this.state.newGroupDesc : '';
				menuArr[this.state.editGroupInd].title = this.state.newGroupTitle ? this.state.newGroupTitle : '';				
				menuArr[this.state.editGroupInd].img = this.state.newGroupImg ? this.state.newGroupImg : '';
				this.toggleGroup();
			}
			else{
				menuArr.push({id: this.getUniqueID('cat'), name: this.state.newGroupName, group: this.state.newGroupLink, status: this.state.newGroupState === 'zichtbaar' ? true : false, closed: this.state.newGroupClosed === 'gesloten' ? true : false, desc: this.state.newGroupDesc, title: this.state.newGroupTitle, img: this.state.newGroupImg, items: []});
				this.setState({newGroupName: '', newGroupLink: 'n.v.t.', newGroupState: 'zichtbaar', newGroupClosed: 'open', newGroupDesc: '', newGroupTitle: '', newGroupImg: ''});
				this.toggleGroup();
			}
			this.props.observableStore.saveEventInfo('menu');
		}
	}
	
	reqFields(type){
		var reqFields = [];
		if(type === 'item'){
			if(!this.state.newItemName || this.state.newItemName === ''){reqFields.push('newItemName')}
			if(!this.state.newItemInfo || this.state.newItemInfo === ''){reqFields.push('newItemInfo')}
			if(this.state.imageSrc === '' && this.state.newItemImg === ''){reqFields.push('newItemImg')}
		}
		if(type === 'group'){
			if(!this.state.newGroupName || this.state.newGroupName === ''){reqFields.push('newGroupName')}
		}
		if(reqFields.length > 0){
			this.setState({reqFields: reqFields});
			return false;
		}
		return true;
	}
	
	UID = 1;	
	getUniqueID(type){
		if(type === 'cat'){var UIDArr = this.props.observableStore.currEvent.menus[this.state.currMenu].menu}
		if(type === 'item'){var UIDArr = this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items}
		for (var i = 0; i < UIDArr.length; i++) {
			if(this.UID === UIDArr[i].id){
				this.UID = this.UID + 1;
				return this.getUniqueID(type);
			}
		}
		var newUID = this.UID;
		this.UID = 1;
		return newUID;
	}

	editGroup = (ind) => {
		this.setState({
			editGroupInd: ind, 
			newGroupName: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].name, 
			newGroupLink: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].group, 
			newGroupState: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].status ? 'zichtbaar' : 'verborgen', 
			newGroupClosed: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].closed ? 'gesloten' : 'open', 
			newGroupDesc: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].desc, 
			newGroupTitle: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].title, 
			newGroupImg: this.props.observableStore.currEvent.menus[this.state.currMenu].menu[ind].img
		});
		this.toggleGroup();
	}
	
	changeGroup = (index) => {
		this.setState({groep: index})
	}
	
	newCat(){
		this.setState({editGroupInd: -1, newGroupName: '', newGroupLink: 'n.v.t.', newGroupState: 'zichtbaar', newGroupClosed: 'open', newGroupDesc: '', newGroupTitle: '', newGroupImg: ''});
		this.toggleGroup();
	}
	
	delGroup = () => {
		if(this.props.observableStore.currEvent.menus[this.state.currMenu].menu.length > 1){
			var newMenu = this.props.observableStore.currEvent.menus[this.state.currMenu].menu;
			newMenu.splice(this.state.editGroupInd, 1);
			this.props.observableStore.currEvent.menus[this.state.currMenu].menu = newMenu;
			this.props.observableStore.saveEventInfo('menu');		
			this.toggleGroup();
		}
		else{
			this.toggleInfoModal('Minimaal één categorie per menu vereist');
		}
	}
	
	onGroupSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.menus[this.state.currMenu].menu = arrayMove(this.props.observableStore.currEvent.menus[this.state.currMenu].menu, oldIndex, newIndex);
		this.setState({groep: newIndex});
		this.props.observableStore.saveEventInfo('menu');		
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items = arrayMove(this.props.observableStore.currEvent.menus[this.state.currMenu].menu[this.state.groep].items, oldIndex, newIndex);
		this.props.observableStore.saveEventInfo('menu');		
	}

	onOptieSortEnd = ({oldIndex, newIndex}) => {
		var newItemOpties = this.state.newItemOpties;
		newItemOpties = arrayMove(newItemOpties, oldIndex, newIndex);	
		this.setState({newItemOpties: newItemOpties});	
	}
	
	loadImg = (event) => {
		var file = event.target.files[0];	
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){			
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {this.setState({imageSrc: reader.result})};		
			}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	  
		}
	}	
	uploadImage = '';
	
	onCropChange = (crop) => {
		this.setState({ crop });
	}

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	}
	
	selectMenu = (val) => {
		if(val === 'new'){
			this.toggleNewMenu();
		}
		else{
			this.setState({currMenu: val});
		}
	}

	clickImport(){
		this.upload.click();
	}
	
	addMenu = () => {
		if(this.props.observableStore.currEvent.menus.length === 10){
			this.toggleInfoModal('Je kunt maximaal 10 menulijsten opslaan');
		}
		else{
			this.props.observableStore.currEvent.menus.push({label: this.state.newMenuLabel, menu: [{group: '1', id: 1, name: 'Meest gekozen', status: true, items: []}]});
			this.setState({currMenu: this.props.observableStore.currEvent.menus.length -1}, () => {this.toggleNewMenu()});
		}
		this.props.observableStore.saveEventInfo('menu');		
	}
	
	editMenu = () => {
		this.props.observableStore.currEvent.menus[this.state.currMenu].label = this.state.newMenuLabel;
		this.toggleEditMenu();
	}
	
	delMenu = () => {
		if(this.props.observableStore.currEvent.menus.length > 1){
			this.setState({currMenu: 0}, () => {this.toggleEditMenu()});
			this.props.observableStore.currEvent.menus.splice(this.state.currMenu, 1);
		}
		else{
			this.toggleInfoModal('Je kunt dit menu niet verwijderen');
		}
		this.props.observableStore.saveEventInfo('menu');
	}
	
	exportMenu(){
		const currMenu = this.props.observableStore.currEvent.menus[this.state.currMenu];
		const blob = new Blob([JSON.stringify({label: currMenu.label, menu: currMenu.menu})], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = currMenu.label;
        a.click();
        a.remove();
	}
	
	importMenu(event){
		if(event.target.files[0]){
			const fileReader = new FileReader();
			fileReader.readAsText(event.target.files[0], "UTF-8");
			fileReader.onload = e => {			  
				if(this.props.observableStore.currEvent.menus.length === 10){
					this.toggleInfoModal('Je kunt maximaal 10 menulijsten opslaan');
				}
				else{
					var importedMenu = JSON.parse(e.target.result);
					this.props.observableStore.currEvent.menus.push({label: importedMenu.label, menu: importedMenu.menu});
					this.toggleInfoModal('Menu is geimporteerd');
				}
				this.props.observableStore.saveEventInfo('menu');
			}
		}
	}

}

export default Menu;

const SortableList = SortableContainer(({items, editItem}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableItem key={index} index={index} ind={index} editItem={editItem} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableItem = SortableElement(({value, ind, editItem}) => 
	<tr style={{opacity: value.status === "verborgen" ? 0.5 : 1, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 8, paddingRight: 0}}><div style={{width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}><img className="menu_img" style={{width: 90}} src={value.img} alt={value.name} /></div></td>
		<td style={{width: window.innerWidth}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.name}</h6></td>
		<td style={{width: window.innerWidth}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.info ? value.info : 'geen omschrijving'}</h6></td>
		<td style={{width: '15%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.price} {value.price === 1 ? 'muntje' : 'muntjes'}</h6></td>
		<td style={{width: '15%'}}>
		{value.status === "uitverkocht" ?
			<h6 style={{width: 125, fontWeight: 500, background: '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Uitverkocht</h6>
		:
			<h6 style={{width: 125, fontWeight: 500, background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>op voorraad</h6>
		}
		</td>
		<td style={{fontSize: 18, minWidth: 90, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editItem(ind, value.name, value.info, value.img, value.price, value.btw, value.serve, value.status, value.reqEighteen, value.opties, value.cup)} style={{cursor:'pointer'}}></i>
			<DragHandle />
		</td>
	</tr>
);

const SortableGroupList = SortableContainer(({items, catStateGroep, changeGroup, editGroup}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableGroup key={index} index={index} ind={index} groep={catStateGroep} editGroup={editGroup} changeGroup={changeGroup} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableGroup = SortableElement(({value, ind, groep, changeGroup, editGroup}) => 
	<div className="menucatnav" style={{backgroundColor: groep === ind ? '#f6f6f6' : '', borderLeftWidth: groep === ind ? 6 : 0}}>
		<div onClick={() => changeGroup(ind)} style={{cursor:'pointer', opacity: value.status ? 1 : 0.5}}>
			<span>{value.name}</span>
		</div>
		<div style={{marginLeft: 10, cursor:'pointer'}}>
			<div className="groupNumIndicator">{value.group}</div>
			<DragHandle />
			<i class="fa fa-edit hightlightIcon2" onClick={() => editGroup(ind)}></i>
		</div>
	</div>
);

const SortableOptieList = SortableContainer(({items, editOptie, delOptie}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableOptie key={index} index={index} ind={index} value={value} editOptie={editOptie} delOptie={delOptie} />))}
			</tbody>
		</Table>
	);
});

const SortableOptie = SortableElement(({value, ind, editOptie, delOptie}) => 
	<div className="menuoptienav" style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}} >
		<div >
			<span>{value}</span>
		</div>
		<div style={{marginLeft: 10, display: 'flex', cursor:'pointer'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editOptie(ind)}></i>
			<i class="fa fa-trash hightlightIcon2" style={{marginLeft: 12}} onClick={() => delOptie(ind)}></i>
			<DragHandle />
		</div>
	</div>
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);

